import CropperImg from '@qs-plus/components/upload/CropperImg.vue'
import UploadFile from '@qs-plus/components/upload/UploadFile.vue'
import { getFileToken, uploadImg } from './upload.util'

export { getFileToken, uploadImg }
CropperImg.props.customUpload = {
  type: Function,
  default: uploadImg,
}
UploadFile.props.getUploadToken = {
  type: Function,
  default: getFileToken,
}
